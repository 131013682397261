/**
 * @generated SignedSource<<f3e16303d05fd3741e0932d2a7d65eb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CalendarConnectionPlatform = "ics" | "outlook" | "%future added value";
export type AuthUserContextRefreshQuery$variables = {};
export type AuthUserContextRefreshQuery$data = {
  readonly viewer: {
    readonly id: string;
    readonly timezone: string;
    readonly avatar: string;
    readonly email: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly fullName: string;
    readonly bio: string;
    readonly cover: string | null;
    readonly creationDatetime: string;
    readonly isVerified: boolean;
    readonly hasAcceptedTerms: boolean;
    readonly calendarConnection: {
      readonly id: string;
      readonly platform: CalendarConnectionPlatform;
    } | null;
    readonly lastActiveOrganizationMembership: {
      readonly organization: {
        readonly primaryDomain: string;
      };
    } | null;
    readonly authProviderId: string | null;
    readonly canCreateTrialOrganization: boolean;
    readonly isTest: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment" | "ProfileAvatarFragment" | "UserflowContextAuthUserFragment">;
  } | null;
};
export type AuthUserContextRefreshQuery = {
  variables: AuthUserContextRefreshQuery$variables;
  response: AuthUserContextRefreshQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creationDatetime",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerified",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAcceptedTerms",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "CalendarConnection",
  "kind": "LinkedField",
  "name": "calendarConnection",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryDomain",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authProviderId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canCreateTrialOrganization",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTest",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthUserContextRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileAvatarWithDetailsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileAvatarFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserflowContextAuthUserFragment"
          },
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "lastActiveOrganizationMembership",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthUserContextRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userflowSignature",
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMembership",
            "kind": "LinkedField",
            "name": "lastActiveOrganizationMembership",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fbff2918f7733e735cd7aa5b7588154",
    "id": null,
    "metadata": {},
    "name": "AuthUserContextRefreshQuery",
    "operationKind": "query",
    "text": "query AuthUserContextRefreshQuery {\n  viewer {\n    id\n    timezone\n    avatar\n    email\n    firstName\n    lastName\n    fullName\n    bio\n    cover\n    creationDatetime\n    ...ProfileAvatarWithDetailsFragment\n    ...ProfileAvatarFragment\n    ...UserflowContextAuthUserFragment\n    isVerified\n    hasAcceptedTerms\n    calendarConnection {\n      id\n      platform\n    }\n    lastActiveOrganizationMembership {\n      organization {\n        primaryDomain\n        id\n      }\n      id\n    }\n    authProviderId\n    canCreateTrialOrganization\n    isTest\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment UserflowContextAuthUserFragment on User {\n  id\n  userflowSignature\n}\n"
  }
};
})();

(node as any).hash = "2a3690916c88d9097bb43ee279f7879e";

export default node;
